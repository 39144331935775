import "jquery-ui/ui/widgets/datepicker";

$(() => {

    const departmentUploadBlock = $(".department-upload-block");

    if(departmentUploadBlock.length < 1)
        return;
    
    $("#datepicker").datepicker({ dateFormat: 'dd/mm yy' });


    $('body').delegate('.department-upload-block__form', 'submit', function (event) {

        var formData = new FormData($(this)[0]);
        var date = $.trim($("#datepicker").val() as any).substring(5, 10) + '-' + $.trim($("#datepicker").val() as string).substring(3, 5) + '-' + $.trim($("#datepicker").val() as string).substring(0, 2);
        formData.append("date", date);
        var err = [];
        var fileInput = $.trim($("#files").val() as string);
    
        if ($.trim($("#title").val() as string) == "") {
            err.push("Du mangler at udfylde titel");
        }
    
        if ($.trim($("#files").val() as string) == "") {
            err.push("Du mangler at vælge et referat");
        }
    
        if ($.trim($("#datepicker").val() as string) == "") {
            err.push("Du mangler at angive dato");
        }
    
        if (fileInput != "") {
            // console.log(fileInput);
            if (!fileInput.match(/.(pdf|doc|docx)$/i)) {
                err.push("Den valgte filtype er ikke tilladt, skal være af typen pdf, doc eller docx");
            }
        }
        if (err.length < 1) {
    
            $.ajax({
                url: '/api/department/CreateSummary/',
                data: formData,
                contentType: false,
                processData: false,
                type: 'POST',
                success: function (data) {
                    // console.log(data);
                    if (data == 2) {
                        $(".errorMsg").remove();
                        $(".department-upload-block__form").before("<div class='col-sm-12 alert-danger errorMsg'><b>Der er sket en fejl:</b><ul><li>Du har ikke rettigheder til at uploade filer</li></ul></div>");
                        $('html,body')
                            .animate({
                                scrollTop: $(".errorMsg").offset().top
                            },
                                'fast');
    
                    } else if (data == 3) {
                        $(".errorMsg").remove();
                        $(".department-upload-block__form").before("<div class='col-sm-12 alert-danger errorMsg'><b>Der er sket en fejl:</b><ul><li>Prøv igen senere. Varer problemet ved så kontakt AAB.</li></ul></div>");
                        $('html,body')
                            .animate({
                                scrollTop: $(".errorMsg").offset().top
                            },
                                'fast');
                    } else {
                        $(".errorMsg").remove();
                        $(".department-upload-block__form")
                            .before("<div class='col-sm-12 alert-success successMsg'><b>Referatet blev uploadet</b><br /><br /><a href='#' class='new_file'>Upload nyt referat &raquo;</a></div>");
                        $('.department-upload-block__form').hide();
                        $('html,body')
                            .animate({
                                scrollTop: $(".successMsg").offset().top
                            },
                                'fast');
                    }
                }
            });
            //return true;
    
        } else {
            event.preventDefault();
            var errorMsg = "";
            err.forEach(function (val) {
                errorMsg += "<li>" + val + "</li>";
            });
            $(".errorMsg").remove();
            $(".department-upload-block__form").before("<div class='col-sm-12 alert-danger errorMsg'><b>Der skete en fejl:</b><ul>" + errorMsg + "</ul></div>");
            $('html,body').animate({
                scrollTop: $(".errorMsg").offset().top
            },
                'fast');
        }
    
    
        return false;
    });
    
    $('body').delegate('.new_file', 'click', function () {
        location.reload();
        return false;
    });
    
});

